import {mapActions, mapState} from 'vuex'
export default {
  created() {
  },
  computed:{
    ...mapState('common',['listFilterParams'])
  },
  watch:{
    listFilterParams:{
      immediate:true,
      handler(val){
        let tableFilter = val[this.filterTableKey]||{}
        Object.keys(tableFilter).some(key=>{
          if(!(this.filterTableKey=='policy'&&key=='all_policy_type'))
            this[key] = tableFilter[key]
        })
      }
    }
  },
  methods: {
    ...mapActions('common', [ 'setListFilter']),
    resetFilter(){
      Object.keys(this.filterData).some(key=>{
        if(!(this.filterTableKey=='policy'&&key=='all_policy_type'))
          this[key] = this.filterData[key]
      })
      this.setListFilter({[this.filterTableKey]:this.filterData})
      this.handlerChange()
    },
    updateFilter(){
      Object.keys(this.filterData).some(key=>{
        this.filterData[key] = this[key]
      })
      this.setListFilter({[this.filterTableKey]:this.filterData})
    }
  }
}
