import axios from '@/jslib/axios'

export const getTicketList = params => {
  return axios({
    method: 'get',
    url: '/admin/tickets',
    params
  })
}
export const getTicketDetail = params => {
  return axios({
    method: 'get',
    url: '/admin/ticket/detail',
    params
  })
}

export const hideTicket = data => {
  return axios({
    method: 'post',
    url: '/admin/ticket/hide',
    data
  })
}
export const delTicket = params => {
  return axios({
    method: 'delete',
    url: '/admin/ticket',
    params
  })
}
export const gtTicketNotify = data => {
  return axios({
    method: 'get',
    url: '/admin/ticket/notify',
    data
  })
}
export const readTicketNotify = data => {
  return axios({
    method: 'get',
    url: '/admin/ticket/read',
    data
  })
}